import React, { useContext, useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ThemeContext } from '../ThemeContext';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Firestore bağlantısını içe aktarmak

const Works = () => {
    const { theme } = useContext(ThemeContext);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        AOS.init();

        const fetchProjects = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'works'));
                const projectsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setProjects(projectsList);
            } catch (error) {
                console.error("Error fetching projects: ", error);
            }
        };

        fetchProjects();
    }, []);

    const leftColumnProjects = projects.filter((_, index) => index % 3 === 0);
    const rightColumnProjects = projects.filter((_, index) => index % 3 !== 0);

    return (
        <main className="main-workspage">
            <section className="projects-area">
                <div className="container">
                    <h1 className="section-heading" data-aos="fade-up">
                        <img src={theme === 'light' ? '/assets/images/star-dark.png' : '/assets/images/star-light.png'} alt="Star" />
                        All Projects
                        <img src={theme === 'light' ? '/assets/images/star-dark.png' : '/assets/images/star-light.png'} alt="Star" />
                    </h1>
                    <div className="row">
                        <div className="col-md-4">
                            {leftColumnProjects.map((project, index) => (
                                <div key={index} data-aos="zoom-in">
                                    <div className="project-item shadow-box">
                                        <a className="overlay-link" href={'/work/'+project.link} aria-label={`Go to ${project.title}`}></a>
                                        <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                                        <div className="project-img">
                                            <img src={project.projectImage} alt="Project" />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="project-info">
                                                <p>{project.category}</p>
                                                <h1>{project.title}</h1>
                                            </div>
                                            <a href={'/work/'+project.link} className="project-btn" aria-label={`Go to ${project.title}`}>
                                                <img src={theme === 'light' ? "/assets/images/icon-light.svg" : "/assets/images/icon-dark.svg"} alt="Button" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col-md-8">
                            <h1 className="section-heading" data-aos="fade-up">
                                <img src={theme === 'light' ? '/assets/images/star-dark.png' : '/assets/images/star-light.png'} alt="Star" />
                                All Projects
                                <img src={theme === 'light' ? '/assets/images/star-dark.png' : '/assets/images/star-light.png'} alt="Star" />
                            </h1>
                            <div className="row">
                                {rightColumnProjects.map((project, index) => (
                                    <div key={index} className="col-md-6" data-aos="zoom-in">
                                        <div className="project-item shadow-box">
                                            <a className="overlay-link" href={'/work/'+project.link} aria-label={`Go to ${project.title}`}></a>
                                            <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                                            <div className="project-img">
                                                <img src={project.projectImage} alt="Project" />
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="project-info">
                                                    <p>{project.category}</p>
                                                    <h1>{project.title}</h1>
                                                </div>
                                                <a href={'/work/'+project.link} className="project-btn" aria-label={`Go to ${project.title}`}>
                                                    <img src={theme === 'light' ? "/assets/images/icon-light.svg" : "/assets/images/icon-dark.svg"} alt="Button" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Works;
