import React, { useEffect, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 

const Home = () => {
    const gradientRef = useRef(null);
    let t = 0;
    let animationFrameId = null;

    useEffect(() => {
        AOS.init();
    }, []);

    const startGradientAnimation = () => {
        const gradientDiv = gradientRef.current;

        const animateGradient = () => {
            t += 0.02;
            const color1 = `rgb(${Math.floor(192 + 64 * Math.cos(t))}, ${Math.floor(192 + 64 * Math.sin(t))}, ${Math.floor(192 + 64 * Math.cos(t * 2))})`;
            const color2 = `rgb(${Math.floor(192 + 64 * Math.sin(t * 2))}, ${Math.floor(192 + 64 * Math.cos(t))}, ${Math.floor(192 + 64 * Math.sin(t))})`;
            gradientDiv.style.background = `linear-gradient(135deg, ${color1}, ${color2})`;
            animationFrameId = requestAnimationFrame(animateGradient);
        };

        animateGradient();
    };

    const stopGradientAnimation = () => {
        if (animationFrameId) {
            cancelAnimationFrame(animationFrameId);
            animationFrameId = null;
        }
        const gradientDiv = gradientRef.current;
        gradientDiv.style.background = '';
    };

    return (
        <main className="main-homepage">
            <section className="about-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6" data-aos="zoom-in">
                            <div className="about-me-box shadow-box">
                                <a className="overlay-link" href="/about"></a>
                                <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                                <div className="img-box">
                                    <img src="/assets/images/photo.jpg" alt="About Me" />
                                </div>
                                <div className="infos">
                                    <h4>A FULLSTACK DEV.</h4>
                                    <h1>Muhammet Hulusi KOÇ</h1>
                                    <p>I am a Fullstack Mobile & Web Developer based in Turkey.</p>
                                    <a href="#" className="about-btn">
                                        <img src="/assets/images/icon-light.svg" alt="Button" />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="about-credentials-wrap">
                                <div data-aos="zoom-in">
                                    <div className="banner shadow-box">
                                        <div className="marquee">
                                            <div>
                                                <span>
                                                    LATEST WORK AND <b>FEATURED</b> <img src="/assets/images/star1.svg" alt="Star" /> 
                                                    LATEST WORK AND <b>FEATURED</b> <img src="/assets/images/star1.svg" alt="Star" /> 
                                                    LATEST WORK AND <b>FEATURED</b> <img src="/assets/images/star1.svg" alt="Star" /> 
                                                    LATEST WORK AND <b>FEATURED</b> LATEST WORK AND <img src="/assets/images/star1.svg" alt="Star" /> 
                                                    LATEST WORK AND <b>FEATURED</b> LATEST WORK AND <img src="/assets/images/star1.svg" alt="Star" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="gx-row d-flex gap-24">
                                    <div data-aos="zoom-in">
                                        <div className="about-crenditials-box info-box shadow-box h-full">
                                            <a className="overlay-link" href="/credentials"></a>
                                            <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                                            <img src='/assets/images/sign.png' alt="Sign" />
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="infos">
                                                    <h4>more about me</h4>
                                                    <h1>Credentials</h1>
                                                </div>

                                                <a href="/credentials" className="about-btn">
                                                    <img src="/assets/images/icon-light.svg" alt="Button" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div data-aos="zoom-in">
                                        <div className="about-project-box info-box shadow-box h-full" >
                                            <a className="overlay-link" href="/works"></a>
                                            <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                                            <img src='/assets/images/my-works.png' alt="My Works" />
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="infos">
                                                    <h4>SHOWCASE</h4>
                                                    <h1>Projects</h1>
                                                </div>

                                                <a href="#" className="about-btn">
                                                    <img src="/assets/images/icon-light.svg" alt="Button" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-24">
                        <div className="col-md-12">
                            <div className="blog-service-profile-wrap d-flex gap-24">
                                <div data-aos="zoom-in">
                                    <div className="about-blog-box info-box shadow-box h-full">
                                        <a href="/blog" className="overlay-link"></a>
                                        <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                                        <img src='/assets/images/gfonts.png' alt="GFonts" />
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="infos">
                                                <h4>Blog</h4>
                                                <h1>GFonts</h1>
                                            </div>

                                            <a href="/blog" className="about-btn">
                                                <img src="/assets/images/icon-light.svg" alt="Button" />
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div data-aos="zoom-in" className="flex-1">
                                    <div className="about-services-box info-box shadow-box h-full">
                                        <a href="/service" className="overlay-link"></a>
                                        <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                                        <div className="icon-boxes">
                                            <i className="iconoir-camera"></i>
                                            <i className="iconoir-design-pencil"></i>
                                            <i className="iconoir-color-filter"></i>
                                            <i className="iconoir-dev-mode-phone"></i>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="infos">
                                                <h4>specialization</h4>
                                                <h1>Services Offering</h1>
                                            </div>

                                            <a href="/service" className="about-btn">
                                                <img src="/assets/images/icon-light.svg" alt="Button" />
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div data-aos="zoom-in">
                                    <div className="about-profile-box info-box shadow-box h-full">
                                        <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                                        <div className="inner-profile-icons shadow-box">
                                            <a href="#">
                                                <i className="iconoir-dribbble"></i>
                                            </a>
                                            <a href="#">
                                                <i className="iconoir-twitter"></i>
                                            </a>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="infos">
                                                <h4>Stay with me</h4>
                                                <h1>Profiles</h1>
                                            </div>

                                            <a href="/contact" className="about-btn">
                                                <img src="/assets/images/icon-light.svg" alt="Button" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-24">
                        <div className="col-md-6" data-aos="zoom-in">
                            <div className="about-client-box info-box shadow-box">
                                <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                                <div className="clients d-flex align-items-start gap-24 justify-content-center">
                                    <div className="client-item">
                                        <h1>07</h1>
                                        <p>Years <br />Experience</p>
                                    </div>

                                    <div className="client-item">
                                        <h1>+125</h1>
                                        <p>CLIENTS <br />WORLDWIDE</p>
                                    </div>

                                    <div className="client-item">
                                        <h1>+210</h1>
                                        <p>Total <br />Projects</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6" data-aos="zoom-in">
                            <div
                                className="about-contact-box info-box shadow-box" 
                                id="gradient"
                                ref={gradientRef}
                                onMouseEnter={startGradientAnimation}
                                onMouseLeave={stopGradientAnimation}
                            >
                                <a className="overlay-link" href="/contact"></a>
                                <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                                <img src="/assets/images/icon2-light.png" alt="Icon" className="star-icon" />
                                <h1>Let's <br />work <span>together.</span></h1>
                                <a href="#" className="about-btn">
                                    <img src="/assets/images/icon-light.svg" alt="Button" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Home;
