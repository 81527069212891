// components/Header.js
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Style.css';  
import { Sun1, Moon } from 'iconsax-react';  
import { ThemeContext } from '../ThemeContext';
import AnimatedLogo from './AnimatedLogo';

const Header = () => {
    const location = useLocation();
    const { theme, toggleTheme } = useContext(ThemeContext);

    return (
        <header className="header-area">
        <div className="container">
            <div className="gx-row d-flex align-items-center justify-content-between mhulusikoc-navbartop" >
            <Link to="/" className="logo">
                    <AnimatedLogo />
                </Link>
                <nav className="navbar">
                    <ul className="menu">
                        <li className={location.pathname === "/" ? "active" : ""}><Link to="/">Home</Link></li>
                        <li className={location.pathname === "/about" ? "active" : ""}><Link to="/about">About</Link></li>
                        <li className={location.pathname === "/works" ? "active" : ""}><Link to="/works">Works</Link></li>
                        <li className={location.pathname === "/contact" ? "active" : ""}><Link to="/contact">Contact</Link></li>
                    </ul>
                    <Link to="/contact" className="theme-btn">Fiyat Teklifi Al</Link>
       
                </nav>
                <div className="d-flex align-items-center">
                    <button onClick={toggleTheme} style={{ background: 'none', border: 'none', marginRight: '10px' }}>
                        {theme === 'light' ? <Moon size="24" color="#323232" /> : <Sun1 size="24" color="#FFFFFF" />}
                    </button>
                    <Link to="/contact" id="teklif-btn"  className="theme-btn">Fiyat Teklifi Al</Link>
                </div>
                <div className="show-menu">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
               </div>
        </header>
    );
};

export default Header;
