import React, { useContext } from 'react';
import { Link } from 'react-router-dom'; 
import { ThemeContext } from '../ThemeContext'; 

const Footer = () => {
    const { theme } = useContext(ThemeContext);

    return (
        <footer className="footer-area">
            <div className="container">
                <div className="footer-content text-center">
                    <Link to="/" className="logo">
                        <img width={140} src={theme === 'light' ? '/assets/images/logo-light.svg' : '/assets/images/logo-dark.svg'} alt="Logo" />
                    </Link>
                    <ul className="footer-menu">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/works">Works</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                    <p className="copyright">
                        &copy; All rights reserved 2024
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
