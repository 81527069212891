// pages/WorkDetails.js
import React, { useContext, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { ThemeContext } from '../ThemeContext';

const WorkDetails = () => {
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <main className="main-workdetails-page">
            <section className="breadcrumb-area">
                <div className="container">
                    <div className="breadcrumb-content" data-aos="fade-up">
                        <p>BRANDING - RAVEN STUDIO</p>
                        <h1 className="section-heading">
                            <img src={theme === 'light' ? '/assets/images/star-dark.png' : '/assets/images/star-light.png'} alt="Star" /> 
                            Aesthetic design for brand new startup 
                            <img src={theme === 'light' ? '/assets/images/star-dark.png' : '/assets/images/star-light.png'} alt="Star" />
                        </h1>
                    </div>
                </div>
            </section>

            <section className="project-details-wrap">
                <div className="project-details-img" data-aos="zoom-in">
                    <img src="/assets/images/project-dt-1.jpeg" alt="Project Details" />
                </div>

                <div className="container">
                    <div data-aos="zoom-in">
                        <div className="d-flex project-infos-wrap shadow-box mb-24">
                            <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                            <img src={theme === 'light' ? '/assets/images/icon2-dark.png' : '/assets/images/icon2-light.png'} alt="Icon" />
                            <div className="project-details-info flex-1">
                                <h3>Raven studio</h3>
                                <p>Sit amet luctus venenatis, lectus magna fringilla in urna, porttitor rhoncus dolor purus non enim praesent in elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis viverra orci sagittis eu volutpat odio facilisis mauris sit.</p>
                                <p>Scelerisque fermentum dui faucibus in ornare quam sit amet luctus venenatis, lectus magna fringilla in urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo.</p>
                            </div>

                            <div className="project-details-info flex-1">
                                <h3>About</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo.</p>
                                <p>Vel fringilla est ullamcorper eget nulla facilisi etiam facilisis dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra orci sagittis eu volutpat odio facilisis mauris sit.</p>
                            </div>
                        </div>
                    </div>

                    <div className="project-details-2-img mb-24" data-aos="zoom-in">
                        <img src="/assets/images/project-dt-1.jpeg" alt="Project" />
                    </div>

                    <div className="row mb-24">
                        <div className="col-md-6" data-aos="zoom-in">
                            <div className="project-details-3-img">
                                <img src="/assets/images/project3.jpeg" alt="Project" />
                            </div>
                        </div>
                        <div className="col-md-6" data-aos="zoom-in">
                            <div className="project-details-3-img">
                                <img src="/assets/images/project4.jpeg" alt="Project" />
                            </div>
                        </div>
                    </div>

                    <div className="row mb-24">
                        <div className="col-md-6" data-aos="zoom-in">
                            <div className="project-details-3-img">
                                <img src="/assets/images/project5.jpeg" alt="Project" />
                            </div>
                        </div>
                        <div className="col-md-6" data-aos="zoom-in">
                            <div className="project-details-3-img">
                                <img src="/assets/images/project6.jpeg" alt="Project" />
                            </div>
                        </div>
                    </div>

                    <div data-aos="zoom-in">
                        <div className="project-about-2 d-flex shadow-box mb-24">
                            <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                            <div className="left-details">
                                <img src={theme === 'light' ? '/assets/images/icon3-dark.png' : '/assets/images/icon3-light.png'} alt="Icon" />
                                <ul>
                                    <li>
                                        <p>Year</p>
                                        <h4>2023</h4>
                                    </li>
                                    <li>
                                        <p>Client</p>
                                        <h4>Raven Studio</h4>
                                    </li>
                                    <li>
                                        <p>Services</p>
                                        <h4>Web Design</h4>
                                    </li>
                                    <li>
                                        <p>Project</p>
                                        <h4>Dynamic</h4>
                                    </li>
                                </ul>
                            </div>
                            <div className="right-details">
                                <h3>Description</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam facilisis dignissim diam quis enim lobortis scelerisque.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam facilisis dignissim diam quis enim lobortis scelerisque in fermentum dui faucibus in ornare.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="project-details-img" data-aos="zoom-in">
                    <img src="/assets/images/project-dt-1.jpeg" alt="Project Details" />
                </div>

                <div className="container d-flex align-items-center justify-content-center" data-aos="zoom-in">
                    <a href="#" className="big-btn shadow-box">
                        Next Project
                    </a>
                </div>
            </section>

            <footer className="footer-area">
                <div className="container">
                    <div className="footer-content text-center">
                        <a href="index.html" className="logo">
                            <img src={theme === 'light' ? '/assets/images/logo-light.svg' : '/assets/images/logo-dark.svg'} alt="Logo" />
                        </a>
                        <ul className="footer-menu">
                            <li><a href="index.html">Home</a></li>
                            <li><a href="about.html">About</a></li>
                            <li><a href="works.html">Works</a></li>
                            <li><a href="contact.html">Contact</a></li>
                        </ul>
                        <p className="copyright">
                            &copy; All rights reserved by <span>WordPress River</span>
                        </p>
                    </div>
                </div>
            </footer>
        </main>
    );
};

export default WorkDetails;
