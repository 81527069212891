// pages/About.js
import React, { useContext } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ThemeContext } from '../ThemeContext';

const About = () => {
    const { theme } = useContext(ThemeContext);

    React.useEffect(() => {
        AOS.init();
    }, []);

    return (
        <main className="main-aboutpage">
            <section className="about-area">
                <div className="container">
                    <div className="d-flex about-me-wrap align-items-start gap-24">
                        <div data-aos="zoom-in">
                            <div className="about-image-box shadow-box">
                                <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                                <div className="image-inner">
                                    <img src="/assets/images/me.jpg" alt="About Me" />
                                </div>
                            </div>
                        </div>

                        <div className="about-details" data-aos="zoom-in">
                            <h1 className="section-heading" data-aos="fade-up">
                                <img src={theme === 'light' ? '/assets/images/star-dark.png' : '/assets/images/star-light.png'} alt="Star" /> Self-summary <img src={theme === 'light' ? '/assets/images/star-dark.png' : '/assets/images/star-light.png'} alt="Star" />
                            </h1>
                            <div className="about-details-inner shadow-box">
                                <img src={theme === 'light' ? '/assets/images/icon2-dark.png' : '/assets/images/icon2-light.png'} alt="Star" />
                                <h1>Muhammet Hulusi KOÇ</h1>
                                <p>Merhaba! Ben Muhammet, deneyimli bir yazılım geliştirici, UI/UX tasarımcısı ve dijital medya uzmanıyım. Flutter, React Native, Unreal Engine gibi güncel teknolojiler ve Adobe Creative Suite araçlarıyla grafik tasarım, video düzenleme ve 3D modelleme alanlarında uzmanlık kazandım. Figma ve Adobe XD ile kullanıcı dostu UI/UX tasarımları geliştiriyorum. Ayrıca, ICO, IDO, IGO, Stake projeleri ile E-ticaret, MLM, WordPress projelerinde de deneyim sahibiyim. İletişim ve proje yönetimi becerilerimle, müşteri memnuniyetini ve projelerin zamanında teslimatını garanti ediyorum. Mevcut ve potansiyel projelerde işbirlikleri için sizlerle tanışmayı ve başarılarımızı birlikte kutlamayı dört gözle bekliyorum!</p>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-24">
                        <div className="col-md-6" data-aos="zoom-in">
                            <div className="about-edc-exp about-experience shadow-box">
                                <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                                <h3>DENEYİM</h3>
                                <ul>
                                <li>
                                        <p className="date">2007 - 2017</p>
                                        <h2>Framer Designer & Developer</h2>
                                        <p className="type">Bluebase Designs</p>
                                    </li>
                                    <li>
                                        <p className="date">2007 - 2017</p>
                                        <h2>Framer Designer & Developer</h2>
                                        <p className="type">Bluebase Designs</p>
                                    </li>
                                    <li>
                                        <p className="date">2007 - 2017</p>
                                        <h2>Framer Designer & Developer</h2>
                                        <p className="type">Bluebase Designs</p>
                                    </li>
                                    <li>
                                        <p className="date">2007 - 2017</p>
                                        <h2>Framer Designer & Developer</h2>
                                        <p className="type">Bluebase Designs</p>
                                    </li>
                                    <li>
                                        <p className="date">2007 - 2017</p>
                                        <h2>Framer Designer & Developer</h2>
                                        <p className="type">Bluebase Designs</p>
                                    </li>
                                    <li>
                                        <p className="date">2007 - 2017</p>
                                        <h2>Framer Designer & Developer</h2>
                                        <p className="type">Bluebase Designs</p>
                                    </li>
                                    <li>
                                        <p className="date">KASKİ Genel Müdürlüğü · Stajyer</p>
                                        <h2>Bilgi İşlem</h2>
                                        <p className="type">Haziran 2015 - Ağustos 2015 · 3 ay</p>
                                    </li>
                                 
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6" data-aos="zoom-in">
                            <div className="about-edc-exp about-education shadow-box">
                                <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                                <h3>EDUCATION</h3>
                                <ul>
                                    <li>
                                        <p className="date">2004 - 2007</p>
                                        <h2>Bachelor Degree in Psychology</h2>
                                        <p className="type">University of California</p>
                                    </li>
                                    <li>
                                        <p className="date">2007 - 2009</p>
                                        <h2>Master Degree in Designing</h2>
                                        <p className="type">University of Texas</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-24">
                        <div className="col-md-12">
                            <div className="d-flex profile-contact-credentials-wrap gap-24">
                                <div data-aos="zoom-in">
                                    <div className="about-profile-box info-box shadow-box h-full">
                                        <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                                        <div className="inner-profile-icons shadow-box">
                                            <a href="#">
                                                <i className="iconoir-dribbble"></i>
                                            </a>
                                            <a href="#">
                                                <i className="iconoir-twitter"></i>
                                            </a>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="infos">
                                                <h4>Stay with me</h4>
                                                <h1>Profiles</h1>
                                            </div>
        
                                            <a href="/contact" className="about-btn">
                                                <img src={theme === 'light' ? '/assets/images/icon-light.svg' : '/assets/images/icon-dark.svg'} alt="Button" />
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div data-aos="zoom-in" className="flex-1">
                                    <div className="about-contact-box info-box shadow-box">
                                        <a className="overlay-link" href="/contact"></a>
                                        <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                                        <img src={theme === 'light' ? '/assets/images/icon2-dark.png' : '/assets/images/icon2-light.png'} alt="Icon" className="star-icon" />
                                        <h1>Let's <br />work <span>together.</span></h1>
                                        <a href="/contact" className="about-btn">
                                            <img src={theme === 'light' ? '/assets/images/icon-light.svg' : '/assets/images/icon-dark.svg'} alt="Button" />
                                        </a>
                                    </div>
                                </div>

                                <div data-aos="zoom-in" className="h-full">
                                    <div className="about-crenditials-box info-box shadow-box">
                                        <a className="overlay-link" href="/credentials"></a>
                                        <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                                        <img src='/assets/images/sign.png' alt="Sign" />
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="infos">
                                                <h4>more about me</h4>
                                                <h1>Credentials</h1>
                                            </div>
        
                                            <a href="/credentials" className="about-btn">
                                                <img src={theme === 'light' ? '/assets/images/icon-light.svg' : '/assets/images/icon-dark.svg'} alt="Button" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default About;
