// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import BlogDetails from './pages/BlogDetails';
import Service from './pages/Service';
import Credentials from './pages/Credentials';
import Works from './pages/Works';
import WorkDetails from './pages/WorkDetails';
import { ThemeProvider } from './ThemeContext';

const App = () => {
    return (
        <ThemeProvider>
            <Router>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:id" element={<BlogDetails />} />
                    <Route path="/service" element={<Service />} />
                    <Route path="/credentials" element={<Credentials />} />
                    <Route path="/works" element={<Works />} />
                    <Route path="/work/:id" element={<WorkDetails />} />
                </Routes>
                <Footer />
            </Router>
        </ThemeProvider>
    );
};

export default App;
