// components/AnimatedLogo.js
import React, { useEffect } from 'react'; 

const AnimatedLogo = () => {
    useEffect(() => {
        const container = document.getElementById('mhulusikoc-logo');
        const svg = container.querySelector('#logo-svg');
        const paths = svg.querySelectorAll('path, line');
        const line = svg.querySelector('.mhulusikocline-1');
        let index = 0;

        function drawNextPath() {
            if (index < paths.length - 1) {
                const path = paths[index];
                const length = path.getTotalLength();
                path.style.strokeDasharray = length;
                path.style.strokeDashoffset = length;
                path.style.transition = '0.25s ease';
                path.style.strokeDashoffset = '0';

                path.addEventListener('transitionend', function onTransitionEnd() {
                    path.removeEventListener('transitionend', onTransitionEnd);
                    index++;
                    drawNextPath();
                }, { once: true });
            } else {
                line.classList.remove('hiddenjans');
            }
        }

        drawNextPath();
    }, []);

    return (
        <div id="mhulusikoc-logo">
            <svg id="logo-svg" data-name="katman 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 246.41 94.94" style={{ width: '60%'}}>
                <g id="mhulusikoc-logo">
                    <path className="mhulusikocline-2" d="M9.85,53.01c-1.81,2.93-8.88,14.55-7.73,18.24.11.34.38,1.21,1.02,1.4,1.22.35,2.87-2.01,3.72-3.16,3.27-4.43,9.39-10.79,11.73-9.87,1.82.72.4,5.52,2.3,6.38,2.91,1.32,8.98-8.76,11.85-7.49,2.27,1-.19,7.89,2.51,9.49,2.74,1.63,8.9-3.31,12.47-7.26,1.23-1.36,4-4.67,8.47-15.54,1.52-3.7,3.4-8.67,5.22-14.7"/>
                    <path className="mhulusikocline-2" d="M62.44,27.78c-6.42,17.47-12.84,34.94-19.27,52.4,2.45-6.3,4.9-12.6,7.35-18.9,10.89-.65,21.78-1.3,32.67-1.95"/>
                    <line className="mhulusikocline-2" x1="91.67" y1="2" x2="61.05" y2="92.94"/>
                    <path className="mhulusikocline-2" d="M93.72,52.48c-3.99,10.05-4.43,13.55-3.44,14.12,2.08,1.2,11.53-10.16,11.45-10.24-.04-.03-2.19,1.52-3.07,4.1-.82,2.41-.59,5.92,1.21,6.98,2.37,1.4,6.91-1.81,9.31-4.75.53-.65,1.62-2.1,4.28-9.77,1.02-2.95,1.54-4.43,2.23-6.89,1.22-4.3,3.8-12.25,9.79-28.25"/>
                    <path className="mhulusikocline-2" d="M127.6,11.77c-12.9,36.86-21.89,62.31-25.22,71.11-.79,2.08-3.37,8.82-2.03,9.6.4.23,1.03-.12,1.94-.76,1.86-1.29,4.56-3.44,7.54-6.89"/>
                    <path className="mhulusikocline-2" d="M124.06,52.48c-4.14,12.5-3.2,14.2-2.61,14.49,1.39.69,6.86-3.29,9.59-8,.27-.47.89-1.6,1.3-1.49,1.01.27-1.22,7.51.68,8.56,2.14,1.18,8.67-5.96,10.12-7.54,2.81-3.07,4.13-5.26,7.45-6.84,1.91-.91,3.67-1.23,4.84-1.35"/>
                    <path className="mhulusikocline-2" d="M156.73,49.84c-1.81.26-4.8.94-7.82,2.98-1.77,1.2-4.77,3.22-4.47,5.21.42,2.85,7.14,2.84,7.45,5.49.08.68-.26,1.5-5.3,5.55-3.14,2.53-5.78,4.4-5.77,4.41,0,.01,5.44-4.54,13.12-9.12,3.52-2.1,6.73-3.74,10.8-7.35,1.43-1.27,2.53-2.39,3.25-3.17"/>
                    <path className="mhulusikocline-2" d="M169.02,52.16c-.94,1.67-6.1,10.97-3.82,12.94,1.81,1.57,7.64-2.03,10.98-4.1,3.08-1.9,3.55-2.71,10.8-7.91,1.86-1.34,4.73-3.37,4.75-3.35.01.02-2.79,2.38-6.8,4.81-4.01,2.43-4.7,2.19-6.14,3.75-.84.9-1.41,1.86-3.26,7.35-1.1,3.26-2.84,8.7-2.7,8.75.14.05,1.84-5.43,5.58-15.66,3.29-8.97,5.96-15.62,7.17-18.59,1.82-4.48,3.43-8.26,4.65-11.08"/>
                    <path className="mhulusikocline-2" d="M180.56,55.89c-.28,1.26-.41,2.94.48,4.28,2.41,3.65,10.4,1.76,11.53,1.49,8.58-2.03,14.47-8.54,14.52-8.47.04.05-3.32,1.97-5.21,5.58-1.75,3.36-2.34,8.36-.28,9.68,2.16,1.38,6.99-1.16,8.38-4.37,1.18-2.73.63-5.05.63-5.05-.15-.65-.32-.87-.21-1.17.31-.89,2.59-1.45,10.94-.2"/>
                    <path className="mhulusikocline-2" d="M232.69,53.66c-.09-.84-.36-1.84-1.12-2.33-2.19-1.41-7.64,1.92-10.24,6.33-1.93,3.27-3.28,8.82-.93,10.89,2.92,2.57,10.17-1.51,13.78-3.54,4.82-2.71,8.17-5.91,10.24-8.19"/>
                    <line className="mhulusikocline-1 hiddenjans" x1="171.63" y1="43.86" x2="171.63" y2="43.86"/>
                </g>
            </svg>
        </div>
    );
};

export default AnimatedLogo;
