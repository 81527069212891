// src/pages/WorkDetails.js
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ThemeContext } from '../ThemeContext';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const WorkDetails = () => {
    const { theme } = useContext(ThemeContext);
    const { id } = useParams();
    const [work, setWork] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        AOS.init();
        fetchWorkDetails();
    }, [id]);

    const fetchWorkDetails = async () => {
        setLoading(true);
        setError(null);
        try {
            const workDocRef = doc(db, 'works', id);
            const workDoc = await getDoc(workDocRef);
            if (workDoc.exists()) {
                setWork(workDoc.data());
            } else {
                console.error("No such document!");
                setError("No work details found.");
            }
        } catch (error) {
            console.error("Error fetching work details:", error);
            setError("Error fetching work details.");
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div></div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!work) {
        return <div>No work details found</div>;
    }

    const {
        kapak,
        about,
        category,
        client,
        desc,
        gorseller,
        link,
        projectImage,
        projectLastImage,
        projectabout,
        slogan,
        title,
        year
    } = work;

    return (
        <main className="main-workdetails-page">
            <section className="breadcrumb-area">
                <div className="container">
                    <div className="breadcrumb-content" data-aos="fade-up">
                        <p>{category+" - "+title}</p>
                        <h1 className="section-heading">
                            <img src={theme === 'light' ? '/assets/images/star-dark.png' : '/assets/images/star-light.png'} alt="Star" />
                            {slogan}
                            <img src={theme === 'light' ? '/assets/images/star-dark.png' : '/assets/images/star-light.png'} alt="Star" />
                        </h1>
                    </div>
                </div>
            </section>

            <section className="project-details-wrap">
                <div className="project-details-img" data-aos="zoom-in">
                    <img src={kapak} alt="Project Details"  style={{ borderTopLeftRadius: '30px', borderTopRightRadius: '30px' }} />
                </div>

                <div className="container">
                    <div data-aos="zoom-in">
                        <div className="d-flex project-infos-wrap shadow-box mb-24">
                            <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                            <img src={theme === 'light' ? '/assets/images/icon2-dark.png' : '/assets/images/icon2-light.png'} alt="Icon" />
                            <div className="project-details-info flex-1">
                                <h3>{title}</h3>
                           
                                <p>{projectabout}</p>
                            </div>

                            <div className="project-details-info flex-1">
                                <h3>About</h3>
                                <p>{about}</p>
                               
                            </div>
                        </div>
                    </div>

                    <div className="row mb-24">
                        {(gorseller || []).map((gorsel, index) => (
                            <div className="project-details-1-img" key={index} data-aos="zoom-in">
                                <img
                                    src={gorsel}
                                    alt={`Project ${index + 1}`}
                                    style={
                                        gorseller.length === 1
                                            ? { borderRadius: '30px' }
                                            : index === 0
                                                ? { borderTopLeftRadius: '30px', borderTopRightRadius: '30px' }
                                                : index === gorseller.length - 1
                                                    ? { borderBottomLeftRadius: '30px', borderBottomRightRadius: '30px' }
                                                    : {}
                                    }
                                />
                            </div>
                        ))}
                    </div>


                    <div data-aos="zoom-in">
                        <div className="project-about-2 d-flex shadow-box mb-24">
                            <img src="/assets/images/bg1.png" alt="BG" className="bg-img" />
                            <div className="left-details">
                                <img src={theme === 'light' ? '/assets/images/icon3-dark.png' : '/assets/images/icon3-light.png'} alt="Icon" />
                                <ul>
                                    <li>
                                        <p>Yıl</p>
                                        <h4>{year}</h4>
                                    </li>
                                    <li>
                                        <p>Müşteri</p>
                                        <h4>{client}</h4>
                                    </li>
                                    <li>
                                        <p>Hizmet</p>
                                        <h4>{category}</h4>
                                    </li>
                                    <li>
                                        <p>Proje</p>
                                        <h4>{title}</h4>
                                    </li>
                                </ul>
                            </div>
                            <div className="right-details">
                                <h3>Açıklama</h3>
                                <p>{desc}</p>
                             </div>
                        </div>
                    </div>
                </div>

                <div className="project-details-img" data-aos="zoom-in">
                    <img src={projectLastImage} alt="Project Details" style={{ borderBottomLeftRadius: '30px', borderBottomRightRadius: '30px' }} />
                </div>

                <div className="container d-flex align-items-center justify-content-center" data-aos="zoom-in">
                    <a href={link || "#"} className="big-btn shadow-box">
                        Next Project
                    </a>
                </div>
            </section>
        </main>
    );
};

export default WorkDetails;
