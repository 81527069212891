// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Firebase yapılandırma bilgilerinizi buraya ekleyin
const firebaseConfig = {
    apiKey: "AIzaSyA22NMlGF1MOxVXf36G0h7FKWuv9AzeUGo",
    authDomain: "mhulusikoc-839d8.firebaseapp.com",
    projectId: "mhulusikoc-839d8",
    storageBucket: "mhulusikoc-839d8.appspot.com",
    messagingSenderId: "841264542382",
    appId: "1:841264542382:web:d40664e94c7c36a5a2333f"
};

// Firebase'i başlatın
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
